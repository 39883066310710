import axios from 'services/axios';

export const getPublisherToken = async (room = 'wroom') => {
  const url = `${process.env.REACT_APP_CDN_API_URL}/api/token/publish`;
  const { data, status } = await axios.post(
    url,
    {
      // stream_name: room,
      stream_name: 'wroom', //hardcoded wroom. remove hardcoded when CDN will support different room names
    },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CDN_API_PUBLISH_TOKEN}`,
      },
    },
  );

  if (status !== 200) {
    throw new Error('Error getting publisher token');
  }

  return data.jwt;
};

export const getSubscriberToken = async (room = 'wroom') => {
  const url = `${process.env.REACT_APP_CDN_API_URL}/api/token/subscribe`;
  const { data, status } = await axios
    .post(
      url,
      {
        stream_name: room,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CDN_API_SUBSCRIBE_TOKEN}`,
        },
      },
    )
    .catch((error) => {
      console.error('Error getting subscriber token', error);
    });

  if (status !== 200) {
    console.log('Error getting subscriber token', data);
    throw new Error('Error getting subscriber token');
  }

  return data.jwt;
};
